import instance from '@/store/api/instance';

export let CpDashSummarySub = function (data) {
    return instance.post('/cpdash/summary/sub', data)
}

export let CpDashSummarySubExport = function (data) {
    return instance.post('/cpdash/summary/sub/export', data)
}

export let CpDashSummaryOtp = function (data) {
    return instance.post('/cpdash/summary/otp', data)
}

export let CpDashSummaryOtpExport = function (data) {
    return instance.post('/cpdash/summary/otp/export', data)
}

export let CpDashSummaryDash = function (data) {
    return instance.post('/cpdash/summary/dash', data)
}