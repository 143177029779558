export let MinimalTimezoneSet = [
    {offset: '+00.00', label: 'GMT+0', tzCode: 'Europe/London'},
    {offset: '+01.00', label: 'GMT+1', tzCode: 'Europe/Berlin'},
    {offset: '+02.00', label: 'GMT+2', tzCode: 'Europe/Helsinki'},
    {offset: '+03.00', label: 'GMT+3', tzCode: 'Europe/Istanbul'},
    {offset: '+04.00', label: 'GMT+4', tzCode: 'Asia/Dubai'},
    {offset: '+05.00', label: 'GMT+5', tzCode: 'Indian/Maldives'},
    {offset: '+06.00', label: 'GMT+6', tzCode: 'Asia/Dhaka'},
    {offset: '+07.00', label: 'GMT+7', tzCode: 'Asia/Bangkok'},
    {offset: '+08.00', label: 'GMT+8', tzCode: 'Asia/Shanghai'},
];

export let TimezoneNumberOffset = function (offset) {
    return parseInt(offset.substr(0, 3))
}

export let TimezoneNumberOffsetFloat = function (offset) {
    return parseFloat(offset)
}

export let BrowserDefaultTimezone = function () {
    return (new Date().getTimezoneOffset() / 60) * -1
}