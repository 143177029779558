<template>
    <div class="bread-wrapper">
        <ul>
            <li v-for="(val, idx) in data" :key="idx">
                <router-link v-if="val.to.length > 0" :to="val.to">{{ val.label }}</router-link>
                <a v-else>{{ val.label }}</a>
                <span v-if="idx%2===0">/</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Breadcrumbs",
    props: ["data"],
}
</script>

<style scoped lang="scss">
.bread-wrapper {
    ul {
        margin: 0;
        padding: 0;
    }

    ul li {
        list-style: none;
        display: inline-block;
    }

    ul li span {
        display: inline-block;
        min-width: 20px;
        text-align: center;
    }

    ul li a.router-link-active {
        font-weight: bold;
    }
}
</style>